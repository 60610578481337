import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { SELECT_INST_MENU } from "../../constants";
import { Request } from "../../library";
import "../../resources/instLayout.css";

import About from "./instAboutInfoComp";
import Contact from "./instContactInfoComp";
import Department from "./instDeptInfoComp";
import Event from "./instEventInfoComp";
import Notice from "./instNoticeInfoComp";
import Team from "./instTeamInfoComp";
import { Helmet } from "react-helmet";

const getComponent = (key, refId) => {
  switch (key) {
    case "contact":
      return <Contact refId={refId} />;

    case "program":
      return <Department refId={refId} />;

    case "evt":
      return <Event refId={refId} />;

    case "notice":
      return <Notice refId={refId} />;

    case "team":
      return <Team refId={refId} />;

    default:
      return <About refId={refId} />;
  }
};

const InstitutionInfo = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [selectedMenu, setSelectedMenu] = useState("about");
  const [infoObj, setInfoObj] = useState({});
  console.log(infoObj);

  console.log(selectedMenu);

  const getById = async () => {
    try {
      if (!id) {
        return null;
      }
      const {
        data: { success, data },
      } = await Request.get(`/api/institution/getById/${id}`);

      if (success) {
        setInfoObj(data);
      }
    } catch (err) {}
  };

  const getClass = (key) =>
    selectedMenu === key ? "l-10 l-10-selected" : "l-10";

  const handleMenuClick = (key) => {
    setSelectedMenu(key);
  };

  useEffect(() => getById(), []);

  const tabClass = "p-3 text-white cursor-pointer";

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{infoObj?.name}</title>
        <meta name="description" content={infoObj?.about?.text} />
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <div className="max-w-screen-2xl mx-auto my-10 py-10 rounded-md shadow-sm px-3 md:px-10   bg-white">
        <h1 className="text-blue-500 text-3xl mb-5 text-blue-600 font-bold text-2xl md:text-4xl ">
          {infoObj.name}
        </h1>
        <div className="flex flex-wrap bg-blue-800   text-base ">
          <div
            className={`${tabClass} ${
              selectedMenu === "about" && "bg-blue-600 text-white"
            }`}
            onClick={() => handleMenuClick("about")}
          >
            About Us
          </div>
          <div
            className={`${tabClass} ${
              selectedMenu === "team" && "bg-blue-600 text-white"
            }`}
            onClick={() => handleMenuClick("team")}
          >
            Our Team
          </div>
          <div
            className={`${tabClass} ${
              selectedMenu === "program" && "bg-blue-600 text-white"
            }`}
            onClick={() => handleMenuClick("program")}
          >
            Departments & Programs
          </div>
          <div
            className={`${tabClass} ${
              selectedMenu === "evt" && "bg-blue-600 text-white"
            }`}
            onClick={() => handleMenuClick("evt")}
          >
            Activities & Events
          </div>
          <div
            className={`${tabClass} ${
              selectedMenu === "notice" && "bg-blue-600 text-white"
            }`}
            onClick={() => handleMenuClick("notice")}
          >
            Informations & Notices
          </div>
          <div
            className={`${tabClass} ${
              selectedMenu === "contact" && "bg-blue-600 text-white"
            }`}
            onClick={() => handleMenuClick("contact")}
          >
            Contact Us
          </div>
        </div>
        <div className="mt-10">{getComponent(selectedMenu, infoObj._id)}</div>
      </div>
    </>
  );
};

export default InstitutionInfo;
