import React, { useEffect, useState } from "react";
import { get } from "lodash";
import { useSelector } from "react-redux";
import { Modal } from "antd";

import { Request } from "../../library";
import { getImage } from "../../utils";

const InstEventDisplay = ({ refId }) => {
  const [items, setItems] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const { loading } = useSelector((state) => state.common);

  useEffect(() => {
    const getItems = async () => {
      try {
        if (refId) {
          const {
            data: { success, data },
          } = await Request.get(
            `/api/institution/getByRefId-activity/${refId}`
          );

          if (success && data) {
            setItems(data);
          }
        }
      } catch (err) {}
    };
    getItems();
  }, [refId]);

  const closeModal = () => {
    setSelectedImage(null);
  };

  return (
    <div className="w-full grid grid-cols-1 gap-5 md:gap-10">
      {!loading &&
        items.map((item) => {
          const links = get(item, "externalLinks", []);
          const imgs = get(item, "images", []);

          return (
            <div
              key={item.title}
              className="border-[1px] border-blue-200 p-3 md:p-10 rounded-md hover:border-blue-600"
            >
              <div>
                <div>
                  <h3 className="text-blue-700 mb-3 text-3xl font-semibold">
                    {item.title}
                  </h3>
                  <p className="text-slate-700 text-xl">{item.description}</p>
                  {links.length > 0 && (
                    <p>
                      <ul className="ext-link-ul">
                        <li style={{ padding: "5px 0px" }}>External Links:</li>
                        {links.map((link) => (
                          <li>
                            <a href={link}>{link}</a>
                          </li>
                        ))}
                      </ul>
                    </p>
                  )}
                </div>

                <div className="">
                  {imgs.length > 0 && (
                    <div
                      className={`my-6 w-full gap-3 py-5 grid grid-cols-1 ${
                        imgs.length > 3 ? "md:grid-cols-4" : "md:grid-cols-2"
                      } ${imgs.length < 2 && "md:grid-cols-1"}`}
                    >
                      {imgs.map((iPath) => (
                        <div className="">
                          <img
                            src={getImage(iPath)}
                            alt=""
                            className="object-cover h-96 w-full object-center rounded-md cursor-pointer"
                            onClick={() => setSelectedImage(getImage(iPath))}
                          />
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>

              <Modal
                onCancel={closeModal}
                visible={!!selectedImage}
                footer={false}
              >
                <img
                  src={selectedImage}
                  alt=""
                  className="w-full h-full mt-5"
                />
              </Modal>
            </div>
          );
        })}
    </div>
  );
};

export default InstEventDisplay;
