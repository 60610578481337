import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Slider from "react-slick";

import { Request } from "../../library";
import { Items } from "../../components";


const InstitutionList = ({institutions}) => {
  const navigate = useNavigate();

  return (
    <div>
      <div className="p-5 grid grid-cols-12 justify-between items-center">
        <div className="col-span-12 md:col-span-9">
          <span className="text-2xl font-bold text-blue-700">Institutions</span>
        </div>
      </div>
      <div className="py-10 bg-white">
        <Items
          showName
          itemsData={institutions}
          wrapperClass="item-div-wrapper"
          selectItem={({ _id: id }) => navigate(`/institution-info/${id}`)}
          />
      </div>
    </div>
  );
}

const BookList = ({books}) => {

  return (
    <div>
      <div className="p-5 grid grid-cols-12 justify-between items-center">
        <div className="col-span-12 md:col-span-9">
          <span className="text-2xl font-bold text-blue-700">Books</span>
        </div>
      </div>
      <div className="py-10 bg-white">
        <Items
          showName
          itemsData={books}
          wrapperClass="item-div-wrapper"
          selectItem={({ _id: id }) => console.log(id)}
          />
      </div>
    </div>
  );
}

const SearchComponent = () => {
  const [itemsData, setItemsData] = useState({});

  // get query from url params
  const query = new URLSearchParams(window.location.search).get("query");

  const getAllItems = async () => {
    const {data: { success, data }} = await Request.get(`/api/home/search?query=${query}`);
    
    if (success) {
      setItemsData(data);
    }
  };

  useEffect(() => getAllItems(), []);

  return (
    <>
    {
      !!Object.keys(itemsData).length && (
        <>
          <InstitutionList institutions={itemsData.institutions} />
          <BookList books={itemsData.books} />
        </>
      )
    }
    </>
  );
}

export default SearchComponent;
