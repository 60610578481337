import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Col, Form, Input, message, Row } from "antd";

import { Request } from '../library';

const OTPVerification = ({ email }) => {

  const navigate = useNavigate();

  const onFinish = values => {
    values.email = email;
    Request
      .post('/api/users/verify-user', values)
      .then(res => {
        if (res.status === 200) {
          message.success('OTP Verified Successfully, Please Login.');
          navigate("/login");
        } else {
          console.log(res.data);
          message.error('Registration Failed, try again later.');
        }
      })
      .catch(() => {
        message.error('Something went wrong');
      });
  };

  return (
    <Form layout="vertical" onFinish={onFinish}>
    <div className="text-center p-8 rounded-2xl">

      <img src="icon.png" className="mx-auto" alt='icons' />

      <h3 className="text-3xl my-5 font-bold capitalize text-blue-700">OTP Verification</h3>
      <Form.Item
        name="otp"
        label="OTP"
        rules={[
          { required: true, message: "Otp is Required" },
          { min: 6, message: "Minlength: 6" },
          { max: 6, message: "Maxlength: 6" },
        ]}
      >
        <Input className="py-3 px-5 rounded-md bg-white focus:bg-white" />
      </Form.Item>
      <p className='text-sm text-gray-500 border-[1px] p-3 my-5 rounded' >
        Should be 6 chars
      </p>

      <Button block className="bg-blue-700 h-auto text-white px-3 py-3 rounded-md" htmlType="submit" type="primary">
        Verify
      </Button>

      <div
        className="flex justify-between mt-5 text-blue-600"
      >
        <Link to="/">
          <a href="/">Go to Home</a>
        </Link>
        <Link to='/login'>
          <a href="/login">Already have account? Login Now</a>
        </Link>
      </div>
    </div>
  </Form>
  )
}

export default OTPVerification;