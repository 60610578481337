import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from "react-router-dom";
import { Button, Col, Form, Input, message, Row } from "antd";

import { LocalStore, Request } from "../../library";
import { LOGIN_SUCCESS } from "../../constants";
import "../../resources/authentication.css";

import Logo from "../../resources/logo.png"

const LoginComponent = () => {
  const { loading } = useSelector(state => state.common);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onFinish = (values) => {
    Request
      .post("/api/users/login", values)
      .then(res => {
        const { success, data: token } = res.data;

        if (success) {
          LocalStore.set(token);
          const { iat, createdAt, expiredAt, ...payload } = LocalStore.decodeToken(token);
          dispatch({ type: LOGIN_SUCCESS, payload });
          message.success("Login successful!!");

          /* Go to dashboard if 'admin' or home page for 'user' */
          if (payload.role === "admin") {
            navigate("/dashboard");
          } else if (payload.role === "institution") {
            navigate("/inst-about");
          } else if (payload.role === "writer") {
            navigate("/writer-dashboard");
          } else {
            navigate("/");
          }
        } else {
          message.error("Unsuccessful login attempt!!");
        }
      })
      .catch((err) => {
        message.error("Something went wrong. Probably, invalid username | password !!");
      });
  };

  useEffect(() => {
    /* Go to dashboard if 'admin', about page if 'institution' or home page for 'user' */
    const payload = LocalStore.decodeToken();

    if (payload) {
      if (payload.role === "admin") {
        navigate("/dashboard");
      }
      if (payload.role === "institution") {
        navigate("/inst-about");
      }
      if (payload.role === "writer") {
        navigate("/writer-dashboard");
      }
      if (payload.role === "user") {
        navigate("/");
      }
    }
  }, []);

  return (
    <div className="authentication p-0">
      {loading && (
        <div className="spinner">
          <div class="spinner-border" role="status"></div>
        </div>
      )}
      {!loading && (<Row className="p-0">
        <Col lg={8} xs={22} className="p-0">
          <Form layout="vertical" className="shadow-2xl rounded-2xl p-0" onFinish={onFinish}>


            <div className="text-center p-8 rounded-2xl">

              <Link to="/">
                <img src={Logo} alt="Logo" className="mx-auto h-10"/>
              </Link>

              <h3 className="text-3xl my-5 font-bold capitalize text-blue-700">Login </h3>

              <Form.Item
                name="username"
                label="Username"

                rules={[{ required: true, message: "" }]}
              >
                <Input className="py-3 px-5 rounded-md bg-white focus:bg-white" />
              </Form.Item>
              <Form.Item
                name="password"
                label="Password"
                rules={[{ required: true, message: "" }]}
              >
                <Input type="password" className="py-3 px-5 rounded-md bg-white focus:bg-white" />
              </Form.Item>

              <Button className="bg-blue-700 h-auto text-white px-3 py-3 rounded-md" block htmlType="submit" type="primary">
                Login
              </Button>

              <div
                className="flex justify-between mt-5 text-blue-600"

              >
                <Link to="/">
                  <a href="/">Go to Home</a>
                </Link>
                <Link to="/register">
                  <a href="/register">Register Now</a>
                </Link>
              </div>
            </div>
          </Form>
        </Col>
      </Row>)}
    </div>
  );
}

export default LoginComponent;
