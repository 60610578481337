import { useDispatch } from "react-redux";
import { Button } from "antd";
import axios from "axios"; // Import axios
import { addToCart } from "../pages/cart/cartModule";
import "../resources/books.css";
import { getImage } from "../utils";

const Items = (props) => {
  const dispatch = useDispatch();

  // Function to download the PDF using Axios
  const downloadFreePdf = async (filename) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/books/getPdfByFileName/${filename}`,
        {
          responseType: "blob", // This is important to handle binary data
        }
      );

      // Create a blob URL and initiate download
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const a = document.createElement("a");
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      a.remove();
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  if (!props.itemsData.length) {
    return (
      <div
        className={props.wrapperClass}
        style={{ color: "red", padding: "20px 0px 0px 20px" }}
      >
        Data not available!!
      </div>
    );
  }

  return (
    <div className="bg-white px-3 pt-0 text-center">
      <div className="bg-transparent gap-3 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-5 pb-10">
        {props.itemsData.map((item) => {
          const dis = item.discount || false;
          return (
            <div
              key={item.name}
              className="shadow-md p-2 rounded-md relative"
              onClick={() => props.selectItem(item)}
              title={
                props.showBtn
                  ? item.name
                  : `List books under ${item.name} category`
              }
            >
              <img
                src={getImage(item.image)}
                alt=""
                className=" h-16 w-32 my-2 rounded-md shadow-md object-contain mx-auto"
              />
              {props.showName && (
                <h6 className=" line-clamp-2 text-blue-500 mt-3">
                  {item.name}
                </h6>
              )}
              {props.showAuthor && (
                <h6 className="absolute group-hover:bg-white top-3 right-0 bg-blue-50 px-2  rounded-l-full">
                  {item.author}
                </h6>
              )}
              {!item.isFree && props.showPrice && (
                <h6 className="my-3 text-lg">
                  <strong className="font-bold text-blue-600">
                    {" "}
                    <i className="las la-wallet"></i>{" "}
                  </strong>
                  Rs. {item.price}
                  {dis && (
                    <span className="absolute  group-hover:bg-white top-3 left-0 bg-blue-50  rounded-r-full px-2">
                      {dis}% off
                    </span>
                  )}
                </h6>
              )}
              {!item.isFree && props.showBtn && (
                <Button
                  className="btn inline"
                  onClick={() => dispatch(addToCart(item))}
                >
                  {props.btnLabel}
                </Button>
              )}
              {item.isFree && (
                <Button
                  className="bg-transparent hover:bg-green-500 mt-5 text-green-700 font-semibold hover:text-white py-2 px-4 border border-green-500 hover:border-transparent rounded-full"
                  onClick={() => downloadFreePdf(item.pdf)}
                >
                  Download
                </Button>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Items;
