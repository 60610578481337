import Profile from "./profileComp";

const UserProfile = () => {
  return (
    <div style={{ padding: "20px 10px 0px 20px", minHeight: "unset" }} >
      <Profile />
    </div>
  );
};

export default UserProfile;
