import { useEffect, useState } from "react";
import { Markup } from "interweave";

import { Request } from "../../library";
import { SliderComponent } from "../../components";
import { SLIDER_OPTIONS } from "../../constants";
import { getImage } from "../../utils";

/* Get Items to display in the SLIDER */
const getSliderItems = (arr = []) => {
  if (arr.length) {
    const items = arr.map((d, idx) => ({ name: `${idx}`, image: d }));
    return items;
  }
  return null;
};

/* Component to display 'About' institution information */
const InstAboutDisplay = ({ refId }) => {
  const [info, setInfo] = useState({});

  useEffect(() => {
    const getData = async () => {
      try {
        if (refId) {
          const {
            data: { success, data },
          } = await Request.get(`/api/institution/getByRefId-about/${refId}`);

          if (success && data) {
            setInfo(data);
          }
        }
      } catch (err) {}
    };

    getData();
  }, [refId]);

  const sliderItems = getSliderItems(info.images);
  console.log(sliderItems);

  return (
    <div className="border-[1px] border-blue-200 p-3 md:p-10 rounded-md hover:border-blue-600">
      {/* Display the text */}
      <div className="">
        <div className="info text-base flex-1">
          {info.html && <Markup content={info.html} />}
          {info.text && <p className="">{info.text}</p>}
        </div>

        {sliderItems && sliderItems.map((item, index) => <div>Hey</div>)}

        {/* Images are displayed as Slider */}
        {/* {sliderItems && (
          <div className=" recent-slider flex-1">
            <SliderComponent
              h6Style={{ color: "white" }}
              wrapperClass="myslider"
              settings={SLIDER_OPTIONS}
              items={sliderItems}
            />
          </div>
        )} */}
      </div>
      <div></div>

      {/* Display HTML as it is written by the User */}
    </div>
  );
};

export default InstAboutDisplay;
