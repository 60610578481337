import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Markup } from 'interweave';
import { get } from "lodash";

import { Request } from "../../library";
import { getImage } from "../../utils";

const InstDepartmentDisplay = ({ refId }) => {
  const [items, setItems] = useState([]);
  const loading = useSelector(state => get(state, "common.loading"));

  useEffect(() => {
    const getItems = async () => {
      try {
        if (refId) {
          const {
            data: { success, data },
          } = await Request.get(`/api/institution/getByRefId-department/${refId}`);
  
          if (success && data) {
            setItems(data);
          }
        }
      } catch (err) {}
    };
    getItems();

  }, [refId]);

  return (
    <div className="">
      {!loading && (
        items.map(item => {
          const links = get(item, "externalLinks", []);
 
          return (
            <div key={item.title} className="flex justify-center gap-5 mb-5 border-b-[1px] pb-5 border-gray-300 flex-wrap">
              {item.image && (
                <img src={getImage(item.image)} alt="" className=" bg-blue-50 h-32 w-32 border-2  rounded-full" />
              )}
              <div className="md:flex-[4] flex-12 text-center md:text-left">
                <h3 className="text-2xl font-bold text-blue-700">{item.name}</h3>
                <p className="border-[1px] border-blue-300 inline-block my-3 px-3 py-1 rounded-md">{item.course}</p>
                {
                  links.length > 0 && (
                    <p>
                      <ul className="ext-link-ul">
                        <li style={{padding: "5px 0px"}}>External Links:</li>
                        {links.map(link => (
                          <li><a href={link}>{link}</a></li>
                        ))}
                      </ul>
                    </p>
                  )
                }
                <p>{item.about}</p>
              </div>
              {item.html && (
                <div className=" flex-1 bg-blue-100 p-3 rounded shadow">
                  <Markup content={item.html} />
                </div>
              )}
            </div>
          );
        })
      )}
    </div>
  );
}

export default InstDepartmentDisplay;
