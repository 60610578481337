import React, { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Layout, message } from "antd";
import { get } from "lodash";
import { ShoppingCartOutlined, LoginOutlined } from "@ant-design/icons";

import LocalStore from "../library/localStore";
import { ALL_BOOKS, LOG_OUT } from "../constants";
import Footer from "./Footer";
import Logo from "../resources/logo.png";
import "../resources/layout.css";
import SiteHeader from "./SiteHeader";
import WebFont from "webfontloader";

const { Header, Content } = Layout;

const HomeLayout = (props) => {
  useEffect(() => {
    WebFont.load({
      google: {
        families: ["Montserrat"],
      },
    });
  }, []);

  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const payload = LocalStore.decodeToken();
  const isLoggedIn =
    get(payload, "role") === "user" && Date.now() < payload.expiredAt;
  const {
    common: { loading },
    cart: { cartItems },
  } = useSelector((state) => state);

  const getClass = (arr = []) => {
    const p = location.pathname.split("/")[1];

    if (!p) {
      return arr.includes("home") ? "m-10 m-10-selected" : "m-10";
    }
    return arr.includes(p) ? "m-10 m-10-selected" : "m-10";
  };

  return (
    <Layout className="font-loader">
      {loading && (
        <div className="spinner absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
          <div class="spinner-border" role="status"></div>
        </div>
      )}

      <SiteHeader />

      <Content
        className=""
        style={{
          ...props.contentStyle,
        }}
      >
        {props.children}
        {props.displayFooter && <Footer />}
      </Content>
    </Layout>
  );
};

export default HomeLayout;
