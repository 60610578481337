import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Col, Form, Input, message, Row } from "antd";

import { Request } from '../../library';
import '../../resources/authentication.css';

import OTPVerification from '../../components/OtpVerification';

import Logo from "../../resources/logo.png"

const REGISTER = 'register';
const OTP_VERIFICATION = 'otp-verification';

const RegisterComponent = () => {
  const { loading } = useSelector(state => state.common);
  // const navigate = useNavigate();

  const [currentTab, setCurrentTab] = useState(REGISTER);
  const [userCreatedEmail, sendUserCreatedEmail] = useState('');

  const onFinish = values => {
    Request
      .post('/api/users/signup', values)
      .then(res => {
        if (res.data.success) {
          message.success('Registration successful, please check your email for verification for OTP. Check spam if not in inbox.');
          sendUserCreatedEmail(values.email);
          setCurrentTab(OTP_VERIFICATION);
        } else {
          message.error('Registration Failed, try again later.');
        }
      })
      .catch(() => {
        message.error('Something went wrong');
      });
  };

  return (
    <div className='authentication'>
      {loading && (
        <div className="spinner">
          <div class="spinner-border" role="status"></div>
        </div>
      )}
      {!loading && (<Row>
        <Col lg={8} xs={22}>

          {currentTab === REGISTER && (
            <Form layout="vertical" onFinish={onFinish}>
            <div className="text-center p-8 rounded-2xl">

              <Link to='/'>
                <img src={Logo} className="mx-auto h-10" alt='icons' />
              </Link>

              <h3 className="text-3xl my-5 font-bold capitalize text-blue-700">Register </h3>
              <Form.Item
                name="username"
                label="Username"
                rules={[
                  { required: true, message: "" },
                  { min: 5, message: "Minlength: 5" },
                  { max: 20, message: "Maxlength: 20" },
                ]}
              >
                <Input className="py-3 px-5 rounded-md bg-white focus:bg-white" />
              </Form.Item>
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  { required: true, message: "" },
                  () => ({
                    validator(rule, value) {
                      const emailReg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
                      if (!value || emailReg.test(value)) {
                        return Promise.resolve();
                      }
                      return Promise.reject("");
                    },
                  }),
                ]}
              >
                <Input className="py-3 px-5 rounded-md bg-white focus:bg-white" />
              </Form.Item>
              <Form.Item
                name="password"
                label="Password"
                rules={[
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue('username') === value) {
                        return Promise.reject("");
                      }

                      const reg = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})');
                      if (reg.test(value)) {
                        return Promise.resolve();
                      }
                      return Promise.reject("");
                    },
                  }),
                ]}
              >
                <Input type='password' className="py-3 px-5 rounded-md bg-white focus:bg-white" />
              </Form.Item>
              <p className='text-sm text-left text-sky-400 border-[1px] p-3 my-5 rounded' >
                At least 8 characters long. <br></br>
                At least one uppercase letter. <br></br>
                At least one lowercase letter. <br></br>
                At least one digit.<br></br>
                At least one special character. <br></br>
              </p>

              <Button block className="bg-blue-700 h-auto text-white px-3 py-3 rounded-md" htmlType="submit" type="primary">
                Register
              </Button>

              <div
                className="flex justify-between mt-5 text-blue-600"
              >
                <Link to="/">
                  <a href="/">Go to Home</a>
                </Link>
                <Link to='/login'>
                  <a href="/login">Already have account? Login Now</a>
                </Link>
              </div>
            </div>
            </Form>
          )}

          {currentTab === OTP_VERIFICATION && (
            <OTPVerification email={userCreatedEmail} />
          )}

        </Col>
      </Row>)}
    </div>
  )
}

export default RegisterComponent;
