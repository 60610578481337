import { PieChart, Pie, Sector, Cell } from "recharts";
import { COLORS } from "./helper";

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
    fill, payload, percent } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <text className="PieChartText" x={cx} y={cy} dy={8} textAnchor="middle" fill='#3DCC91'>Strong Buy</text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none"/>
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none"/>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`${payload.name}`}</text>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
        {`${payload.value} Analysts (${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
};

const SimplePieChart = ({
  data,
  width=310,
  height=200,
  innerRadius=30,
  outerRadius=50,
  showLabel=true,
}) => (
  <PieChart width={width} height={height}>
    <Pie
      isAnimationActive={false}
      activeShape={renderActiveShape} 
      data={data}
      label={entry => showLabel ? entry.name : ""}
      labelLine={false}
      innerRadius={innerRadius}
      outerRadius={outerRadius}
      fill="#8884d8"
    >
      {
        data.map((entry, index) => <Cell fill={COLORS[index % COLORS.length]}/>)
      }
    </Pie>
  </PieChart>
);

export default SimplePieChart;
